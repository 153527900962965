import {
  TRANSLOCO_CONFIG,
  TRANSLOCO_LOADER,
  translocoConfig,
  TranslocoLoader,
  TranslocoModule
} from '@ngneat/transloco';
import {Injectable, isDevMode, NgModule} from '@angular/core';
import {Translation} from 'primeng/api';
import {HttpClient} from '@angular/common/http';
import {environment} from '../environments/environment';

/**
 * Loader für Übersetzungen
 */
@Injectable({providedIn: 'root'})
export class TranslocoHttpLoader implements TranslocoLoader {
  constructor(
    private httpClient: HttpClient
  ) {
  }

  /**
   * Lädt die Übersetzungen per HTTP-Client
   * @param lang
   */
  getTranslation(lang: string) {
    const kuerzel = lang.split('-')[0];
    return this.httpClient.get<Translation>(environment.baseRef + `assets/i18n/${kuerzel}.json`);
  }
}

/**
 * Konfiguration für das Transloco-Module
 */
@NgModule({
  exports: [TranslocoModule],
  providers: [
    {
      provide: TRANSLOCO_CONFIG,
      useValue: translocoConfig({
        // Hier die bekannten Sprachen einstellen
        availableLangs: ['de', 'de-de', 'en', 'en-gb', 'en-us', 'fr', 'fr-fr', 'hu', 'hu-hu', 'pl', 'pl-pl', 'ru', 'ru-ru', 'pt', 'pt-pt'],
        // Standard- und Fallbacksprache ist Englisch
        defaultLang: liefereKultursprache() ?? 'en',
        fallbackLang: 'en',
        // Komponenten neu rendern, wenn Sprache geändert wurde
        reRenderOnLangChange: true,
        prodMode: !isDevMode(),
      })
    },
    {provide: TRANSLOCO_LOADER, useClass: TranslocoHttpLoader},
  ]
})
export class TranslocoRootModule {
}

export function liefereKultursprache() {
  const navigator = window.navigator;
  const lang = navigator.languages ? navigator.languages.find((lang) => lang.indexOf('-') > -1 || lang.indexOf('_') > -1) : navigator.language;
  return lang?.toLowerCase();
}
